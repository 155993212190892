export default {
    alert: 'Stay alert! Full website coming in fall 2024',
    files: {
        contract: {
            label: 'Fill in the Exhibition Space Rental Contract',
            link: 'https://assets.boitebeet.com/jdiq/18/jdiq-2025-contract-en.pdf'
        },
        exhibition_application: {
            label: 'Make an Application Request for Exhibit Space',
            link: 'https://assets.boitebeet.com/jdiq/35/jdiq-2025-exhibition-application-en.pdf'
        },
        floor_plan: {
            label: 'View the Floor Plan JDIQ 2025',
            link: 'https://assets.boitebeet.com/jdiq/22/jdiq-2025-floor-plan-en.pdf'
        },
        prospectus: {
            label: 'Download the Exhibitor Prospectus',
            link: 'https://assets.boitebeet.com/jdiq/25/jdiq-2025-prospectus-en.pdf'
        }
    },
    footer: 'Questions? Contact us at',
    gdpr: {
        banner: {
            accept: 'ACCEPT COOKIES',
            description: 'We use cookies to make this site work and to help us make improvements. Clicking accept will enable us to set recommended analytics cookies.',
            settings: 'CHANGE COOKIES SETTINGS',
            title: 'Our use of cookies'
        },
        settings: {
            analytics: {
                description: 'We\'d like to set Google Analytics cookies to help us to improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work, please see our Privacy & Cookies page.',
                title: 'Analytics cookies'
            },
            description: 'We use essential cookies to make our site work. We\'d also like to set optional analytics cookies to help us improve it. We won\'t set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences. For more detailed information about the cookies we use, see our Privacy & Cookies page.',
            essentials: {
                description: 'Essential cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.',
                title: 'Essential cookies'
            },
            save: 'Save and close',
            title: 'Our use of cookies'
        }
    },
    hat: 'Next edition - May 30 to June 3, 2025',
    locales: {
        en: 'Anglais',
        fr: 'Français'
    },
    title: 'Journées dentaires internationales du Québec\'s website is getting a makeover!',
}