export default {
    alert: 'Restez à l\'affût! Site complet à l\'automne 2024',
    files: {
        contract: {
            label: 'Remplir le contrat de location d\'espace',
            link: 'https://assets.boitebeet.com/jdiq/33/jdiq-2025-contract-fr.pdf'
        },
        exhibition_application: {
            label: 'Faire une demande de réservation pour exposer',
            link: 'https://assets.boitebeet.com/jdiq/21/jdiq-2025-exhibition-application-fr.pdf'
        },
        floor_plan: {
            label: 'Consulter le plan d\'exposition JDIQ 2025',
            link: 'https://assets.boitebeet.com/jdiq/23/jdiq-2025-floor-plan-fr.pdf'
        },
        prospectus: {
            label: 'Téléchargez le prospectus des exposants',
            link: 'https://assets.boitebeet.com/jdiq/34/jdiq-2025-prospectus-fr.pdf'
        }
    },
    footer: 'Questions? Écrivez-nous au',
    gdpr: {
        banner: {
            accept: 'Accepter',
            description: 'Nous utilisons des témoins (cookies) afin d’améliorer votre expérience de navigation et personnaliser le contenu de ce site internet. En cliquant sur Accepter, vous nous permettez d’améliorer la façon dont le site est construit.',
            settings: 'Modifier les préférences',
            title: 'Notre utilisation des témoins (cookies)'
        },
        settings: {
            analytics: {
                description: 'Nous aimerions définir des fichiers témoins Google Analytics pour nous aider à améliorer notre site Web en collectant et en rapportant des informations sur la façon dont vous l\'utilisez. Les fichiers témoins collectent des informations de manière complètement anonyme. Pour plus d\'informations sur le fonctionnement de ces fichiers témoins, veuillez consulter notre page Énoncé de confidentialité.',
                title: 'Fichiers de témoins d’analyse'
            },
            description: 'Nous utilisons des témoins (cookies) pour faire fonctionner notre site. Nous utilisons aussi des témoins d\'analyse pour nous aider à l\'améliorer au fil du temps. Nous ne définirons pas de témoins à moins que vous ne les activiez. L\'utilisation de cet outil installera un témoin sur votre appareil pour mémoriser vos préférences. Pour plus d\'informations sur les témoins que nous utilisons, consultez notre page Énoncé de confidentialité.',
            essentials: {
                description: 'Les témoins essentiels activent des fonctionnalités de base telles que la sécurité, la gestion du réseau et l\'accessibilité. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web.',
                title: 'Témoins essentiels'
            },
            save: 'Enregistrer et fermer',
            title: 'Notre utilisation des témoins (cookies)'
        }
    },
    hat: 'Prochaine édition - 30 mai au 3 juin 2025',
    locales: {
        en: 'English',
        fr: 'French'
    },
    title: 'Le site web des Journées dentaires internationales du Québec se refait une beauté!',
}