import {createI18n} from 'vue-i18n'
import en from '@/i18n/en.js'
import fr from '@/i18n/fr.js'

export default createI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {
        en: en,
        fr: fr
    }
})
